// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ecosystem-tsx": () => import("./../../../src/pages/ecosystem.tsx" /* webpackChunkName: "component---src-pages-ecosystem-tsx" */),
  "component---src-pages-fund-tsx": () => import("./../../../src/pages/fund.tsx" /* webpackChunkName: "component---src-pages-fund-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-philosophy-tsx": () => import("./../../../src/pages/philosophy.tsx" /* webpackChunkName: "component---src-pages-philosophy-tsx" */),
  "component---src-pages-zh-ecosystem-tsx": () => import("./../../../src/pages/zh/ecosystem.tsx" /* webpackChunkName: "component---src-pages-zh-ecosystem-tsx" */),
  "component---src-pages-zh-fund-tsx": () => import("./../../../src/pages/zh/fund.tsx" /* webpackChunkName: "component---src-pages-zh-fund-tsx" */),
  "component---src-pages-zh-index-tsx": () => import("./../../../src/pages/zh/index.tsx" /* webpackChunkName: "component---src-pages-zh-index-tsx" */),
  "component---src-pages-zh-philosophy-tsx": () => import("./../../../src/pages/zh/philosophy.tsx" /* webpackChunkName: "component---src-pages-zh-philosophy-tsx" */)
}

